import * as React from 'react';
import type {
  FeatureAppDefinition,
  FeatureAppEnvironment,
  FeatureServices,
} from '@feature-hub/core';
import type { ReactFeatureApp } from '@feature-hub/react';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { ContentContextProvider } from '@oneaudi/feature-app-utils';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { ButtonsContentLoader } from './components/Buttons';
import { ButtonsContent } from '../types';
import { FalconContent, mapContent } from './utils/normalize-falcon-data';
import { LayerManagerContext, TrackingContext } from './context';

interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'layer-manager': LayerManagerV27;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

export const getAsyncState = async (contentService: ContentServiceV1): Promise<ButtonsContent> => {
  const contentServiceData: ButtonsContent | FalconContent | undefined =
    await contentService?.getContent();
  return mapContent(contentServiceData);
};

export default {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'layer-manager': '^2.7.2',
      'audi-render-mode-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },
  create: ({
    featureServices: {
      'audi-content-service': contentService,
      's2:async-ssr-manager': asyncSSRManager,
      's2:serialized-state-manager': serializedStateManager,
      'layer-manager': layerManager,
      'audi-render-mode-service': renderModeService,
      'audi-tracking-service': trackingService,
    },
    config,
  }: FeatureAppEnvironment<FeatureServiceDependencies, ButtonsContent>) => {
    if (trackingService) {
      /* eslint-disable no-param-reassign */
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }
    let asyncState: ButtonsContent;
    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          asyncState = await getAsyncState(contentService);
          serializedStateManager?.register(() => JSON.stringify(asyncState));
        })(),
      );
    } else {
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncState = JSON.parse(serializedState);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        (async () => {
          asyncState = await getAsyncState(contentService);
        })();
      }
    }

    return {
      render: () => (
        <ContentContextProvider contentService={contentService}>
          <LayerManagerContext.Provider value={layerManager}>
            {/* @ts-expect-error optional dependency */}
            <TrackingContext.Provider value={trackingService}>
              <UniversalEditorProvider
                contentService={contentService}
                renderModeService={renderModeService}
              >
                <ButtonsContentLoader config={config} ssrContent={asyncState} />
              </UniversalEditorProvider>
            </TrackingContext.Provider>
          </LayerManagerContext.Provider>
        </ContentContextProvider>
      ),
    };
  },
} as FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies>;
