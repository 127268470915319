/* eslint-disable no-nested-ternary */
import * as React from 'react';
import styled from 'styled-components';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';

import {
  audiDarkTheme,
  audiLightTheme,
  Theme,
  ThemeProvider,
  ButtonGroup,
  Button,
  Badge,
  ButtonProps,
} from '@audi/audi-ui-react';
import {
  COLOR_BASE_BRAND_BLACK,
  PAGE_MARGIN_XS,
  PAGE_MARGIN_S,
  PAGE_MARGIN_M,
  PAGE_MARGIN_L,
  BREAKPOINT_XS,
  BREAKPOINT_S,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
  COLOR_BASE_BRAND_WHITE,
  SPACING_L_S,
  PAGE_MARGIN_XL,
} from '@audi/audi-ui-design-tokens';
import { useContent } from '@oneaudi/feature-app-utils';

import { LayerContentHTML } from '@oneaudi/fa-one-layer';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { TrackingClickEvent, useTracking } from '../tracking';
import type { ButtonsContent, ButtonElement, OpenIn } from '../../types';
import {
  getSelectedButtonCombination,
  isDarkTheme,
  isDebugMode,
  isEmpty,
  setHeadlessUrl,
} from '../utils';
import { APP_ID } from '../../environment';
import { mapContent } from '../utils/normalize-falcon-data';
import { LayerManagerContext, TrackingContext } from '../context';

const alignmentToSlot = (alignment: ButtonsContent['alignment']): 'start' | 'end' =>
  alignment === 'right' ? 'end' : 'start';

export const StyledContainer = styled.div<{
  paddingTop?: boolean;
  alignment?: ButtonsContent['alignment'];
  isDoubleTextButton?: boolean;
  isTextOnly?: boolean;
  textTwoLayout?: 'horizontal' | 'vertical';
}>`
  transition: 1s all ease;
  transform: scale(1);
  display: flex;
  align-items: center;

  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? COLOR_BASE_BRAND_BLACK : COLOR_BASE_BRAND_WHITE};

  @media screen and (min-width: ${BREAKPOINT_XS}px) {
    padding-inline-start: ${PAGE_MARGIN_XS}px;
    padding-inline-end: ${PAGE_MARGIN_XS}px;
  }
  @media screen and (min-width: ${BREAKPOINT_S}px) {
    padding-inline-start: ${PAGE_MARGIN_S}px;
    padding-inline-end: ${PAGE_MARGIN_S}px;
  }
  @media screen and (min-width: ${BREAKPOINT_M}px) {
    padding-inline-start: ${PAGE_MARGIN_M}px;
    padding-inline-end: ${PAGE_MARGIN_M}px;
  }
  @media screen and (min-width: ${BREAKPOINT_L}px) {
    padding-inline-start: ${PAGE_MARGIN_L}px;
    padding-inline-end: ${PAGE_MARGIN_L}px;
  }
  @media screen and (min-width: ${BREAKPOINT_XL}px) {
    padding-inline-start: ${PAGE_MARGIN_XL}px;
    padding-inline-end: ${PAGE_MARGIN_XL}px;
  }

  ${({ alignment }) =>
    !alignment
      ? ''
      : alignment === 'center'
        ? 'justify-content: center;'
        : ` justify-content: flex-${alignmentToSlot(alignment)};`}

  // Force mobile portrait layout on text only to left align
  @media screen and (min-width: ${BREAKPOINT_XS}px) {
    ${({ isTextOnly }) => (isTextOnly ? 'justify-content: flex-start;' : '')};
  }

  // check again text only screens greater than mobile portrait
  @media screen and (min-width: ${BREAKPOINT_M}px) {
    ${({ alignment }) =>
      !alignment
        ? ''
        : alignment === 'center'
          ? 'justify-content: center;'
          : ` justify-content: flex-${alignmentToSlot(alignment)};`}
  }

  .buttons {
    display: flex;
    ${({ isTextOnly }) => (!isTextOnly ? 'width 100%;' : '')};
    ${({ paddingTop }) => (!paddingTop ? `margin-block-start: 0;` : '')}
    ${({ alignment }) =>
      !alignment
        ? ''
        : alignment === 'center'
          ? 'justify-content: center;'
          : ` justify-content: flex-${alignmentToSlot(alignment)};`}

@media screen and (min-width: ${BREAKPOINT_L}px) {
      ${({ isTextOnly }) => (!isTextOnly ? 'width auto;' : '')};
    }
  }

  .buttons > * {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${({ isTextOnly }) => (!isTextOnly ? 'width 100%;' : '')};
    ${({ isTextOnly }) => (isTextOnly ? 'align-items: flex-start;' : '')};
    ${({ isTextOnly, textTwoLayout, isDoubleTextButton }) =>
      isTextOnly && isDoubleTextButton && textTwoLayout === 'horizontal'
        ? 'flex-direction: row;'
        : ''};

    // check again text only screens greater than mobile portrait
    @media screen and (min-width: ${BREAKPOINT_M}px) {
      ${({ isTextOnly }) =>
        !isTextOnly ? 'flex-direction: row; align-items: flex-start; width: auto;' : ''};
      ${({ alignment }) =>
        !alignment
          ? ''
          : alignment === 'center'
            ? 'align-items: center;'
            : ` align-items: flex-${alignmentToSlot(alignment)};`}
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledButton = styled(Button)<{
  isTextOnly?: boolean;
  textTwoLayout?: 'horizontal' | 'vertical';
  isDoubleTextButton?: boolean;
  buttonLength?: number;
}>`
  ${({ isTextOnly }) => (isTextOnly ? `margin-bottom: ${SPACING_L_S}px;` : '')};

  ${({ buttonLength }) => (buttonLength === 1 ? 'margin-bottom: 0;' : '')};
  ${({ isTextOnly, isDoubleTextButton, textTwoLayout }) =>
    isTextOnly && isDoubleTextButton && textTwoLayout === 'horizontal'
      ? `&:first-child {margin-right: ${SPACING_L_S}px; margin-bottom: 0;}`
      : ''};

  &:last-child {
    margin-bottom: 0;
  }
`;
function getPropertyName(i: number): string | undefined {
  let labelName = '';
  if (i === 0) {
    labelName = 'firstLabel';
  }
  if (i === 1) {
    labelName = 'secondLabel';
  }
  if (i === 2) {
    labelName = 'thirdLabel';
  }
  return labelName;
}
export const Buttons = (props: ButtonsContent): React.ReactElement => {
  const ref = React.useRef(null);
  const layerManager = React.useContext(LayerManagerContext);
  const trackingService = React.useContext(TrackingContext);
  const { sendReadyEvent, registerImpressionTracking, sendClickEvent } =
    useTracking(trackingService);

  if (typeof window !== 'undefined') {
    // skip tracking on the server side where window object is undefined

    React.useEffect(() => {
      sendReadyEvent();
    }, []);

    React.useEffect(() => {
      if (ref?.current) registerImpressionTracking(ref);
    }, [ref]);
  }
  const sendButtonClickEvent = (
    label: string,
    url: string,
    variant: ButtonProps['variant'],
    openin: OpenIn,
    position: string,
  ) => {
    const event: TrackingClickEvent = {
      linkLabel: label,
      linkUrl: url,
      elementName: variant,
      headline: '',
      OpenIn: openin,
      position,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return sendClickEvent(event);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOpenInLayer = React.useCallback((url: string) => {
    layerManager?.openFocusLayer(
      () => <LayerContentHTML enableDeepLinking url={`${setHeadlessUrl(url)}`} />,
      {},
      {
        userCloseable: true,
        size: FocusLayerSizeV2.A,
      },
    );
  }, []);

  const buttons: ButtonElement = getSelectedButtonCombination(props);

  const isDoubleTextButton =
    buttons?.buttonContent.filter((x) => x?.variant === 'text')?.length === 2;

  const isTextOnly = buttons?.buttonContent.filter((x) => x?.variant === 'text')?.length > 0;

  const textTwoLayout = buttons?.textTwoLayout || 'horizontal';

  // onesight tracking was removed because it will be discontinued in arcade
  // TODO tracking-service still needs to be implemented instead of onesight tracking

  return (
    <ThemeProvider theme={props?.themeColor === 'dark' ? audiDarkTheme : audiLightTheme}>
      <UeContainer propertyPath="buttons" label="CTAs">
        <StyledContainer
          alignment={props?.alignment}
          isDoubleTextButton={isDoubleTextButton}
          data-testid="buttons"
          ref={ref}
          paddingTop={props?.paddingTop}
          isTextOnly={isTextOnly}
          textTwoLayout={textTwoLayout}
        >
          <ButtonGroup
            spaceStackStart={isTextOnly ? 'l' : 'xxl'}
            spaceStackEnd={isTextOnly ? 'l' : 'xxl'}
            className="buttons"
            variant="block-buttons"
          >
            {buttons?.buttonContent.map((x, i) => {
              return x?.label && x.url ? (
                x.label && x.openIn === 'chatbot' ? (
                  <UeReference propertyPath={`buttons.${i}`} label="CTA">
                    {(ueProps) => (
                      <StyledButton
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${x.label}_${i}`}
                        // link that has the "nm-j-poa" classname attached to it will open the chat bot upon clicking
                        className={`nm-j-poa button button-${i + 1} button-${i + 1}--${
                          x.variant || 'primary'
                        }`}
                        variant={x.variant || 'primary'}
                        aria-label={x.ariaLabel}
                        onClick={() => {
                          sendButtonClickEvent(
                            x.label,
                            x.url,
                            // @ts-expect-error type not undefined
                            x.variant,
                            x.openIn,
                            (i + 1).toString(),
                          );
                        }}
                        isTextOnly={isTextOnly}
                        data-testid="button"
                        textTwoLayout={textTwoLayout}
                        isDoubleTextButton={isDoubleTextButton}
                        buttonLength={buttons.buttonContent.length}
                        {...ueProps}
                      >
                        <UeElement type="text" property={getPropertyName(i)} label="Label">
                          {x.label}
                        </UeElement>
                      </StyledButton>
                    )}
                  </UeReference>
                ) : (
                  <UeReference propertyPath={`buttons.${i}`} label="CTA">
                    {(ueProps) => (
                      <StyledButton
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${x.label}_${i}`}
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        className={`button button-${i + 1} button-${i + 1}--${x.variant || 'primary'}`}
                        variant={x.variant || 'primary'}
                        href={x.openIn === 'layer' ? undefined : x.url}
                        newWindow={x.openIn === 'new' || x.openIn == null}
                        aria-label={x.ariaLabel}
                        data-testid="button"
                        onClick={() => {
                          sendButtonClickEvent(
                            x.label,
                            x?.url,
                            // @ts-expect-error type not undefined
                            x.variant,
                            x.openIn,
                            (i + 1).toString(),
                          );
                          if (x.openIn === 'layer') handleOpenInLayer(x?.url);
                        }}
                        isTextOnly={isTextOnly}
                        textTwoLayout={textTwoLayout}
                        isDoubleTextButton={isDoubleTextButton}
                        buttonLength={buttons.buttonContent.length}
                        {...ueProps}
                      >
                        <UeElement type="text" property={getPropertyName(i)} label="Label">
                          {x.label}
                        </UeElement>
                      </StyledButton>
                    )}
                  </UeReference>
                )
              ) : (
                // use this UI component to warn the author if values in combinations have been missed
                <Badge
                  label={{
                    text: `Button ${x?.variant} has empty label or empty url!`,
                  }}
                  variant="alert"
                />
              );
            })}
          </ButtonGroup>
        </StyledContainer>
      </UeContainer>
    </ThemeProvider>
  );
};

export const ButtonsContentLoader = ({
  config: cypressTestData,
  ssrContent,
}: {
  config?: ButtonsContent;
  ssrContent?: ButtonsContent;
}): React.ReactElement | null => {
  const content =
    BROWSER && typeof ssrContent === 'undefined'
      ? cypressTestData && !isEmpty(cypressTestData)
        ? cypressTestData
        : mapContent(useContent<ButtonsContent>())
      : ssrContent;

  if (isDebugMode())
    // eslint-disable-next-line no-console
    console.debug(`${APP_ID} ->`, {
      content,
    });

  if (!content) return null;

  return <Buttons {...content} />;
};
